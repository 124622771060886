import { Button } from '@loanpal/lumos2-mfe';
import { signIn } from 'auth-astro/client';

export default function SignInButton({ callbackUrl }: { callbackUrl: string }) {
	const login = () => signIn('okta', { callbackUrl });

	return (
		<>
			<Button onClick={login}>Sign In</Button>
		</>
	);
}
